<template>
  <div class="main-content" style="height: 100vh;background-color: #FFFFFF;">
    <div class="dashboard-banner position-relative">
      <div class="d-flex justify-content-center align-items-end" style="padding-bottom: 10vh !important;">
        <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("set_sec_password") }}</h4>
      </div>
        <div class="appBar">
          <a @click="goBack">
            <i class="fa fa-chevron-left"></i>
          </a>
      </div>
     
    </div>

    <div class="mainpage px-3 pt-4">
      <b-row align-h="center">
        <b-col cols="12">
          <b-form @submit.prevent="submitForm">

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <b-card class="px-3 py-2 card-input-box" no-body>
                  <h5 class="label-form mb-0 text-10">
                    {{ $t("new_password") }}
                  </h5>
                  <input
                    type="password"
                    class="form-control label-input"
                    v-model="password"
                    required
                  />
                </b-card>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <b-card class="px-3 py-2 card-input-box" no-body>
                  <h5 class="label-form mb-0 text-10">
                    {{ $t("confirm_new_password") }}
                  </h5>
                  <input
                    type="password"
                    class="form-control label-input"
                    v-model="password_confirmation"
                    required
                  />
                </b-card>
              </div>
            </div>

            <b-button
              type="submit"
              class="mt-5 mb-5 btn-curved"
              variant="primary"
              block
              :disabled="isLoading"
              >{{ isLoading ? $t("loading...") : $t("submit") }}</b-button
            >

          </b-form>
        </b-col>
      </b-row>
    </div>
    <Dialog ref="msg"></Dialog>
  </div>
</template>

<script>
import { setSecPassword } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
  },
  components: {
    Dialog,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      myVar: null,
      sending: false,
      isLoading: false,
    };
  },
  props: ["success"],
  methods: {
    ...mapActions(["signOut"]),
    goBack() {
      this.signOut();

      this.$router.push("/web/sessions/signIn");
    },
    submitForm() {
      let formData = new FormData();
      var self = this;
      formData.append("password", this.password);
      formData.append("password_confirmation", this.password_confirmation);
      var result = setSecPassword(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.code == 0) {
            self.$refs.msg.makeToast("success", self.$t(value.data.message));
            self.password = "";
            self.password_confirmation = "";
            setTimeout(() => {
              var current = location.origin + "/";
              window.location.href = current + "web";
            }, 2000);
          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
    loadItems() {},
  },
  created() {
    this.loadItems();
    this.username = localStorage.getItem("username");
  },
};
</script>

<style scoped>
.main-content {
  max-width: 420px;
  margin: auto;
}
</style>